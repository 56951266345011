import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

export const data = graphql`
    query {
        NotFound: file(relativePath: { eq: "404.svg" }) {
            publicURL
        }
    }
`;

// markup
const NotFoundPage = ({ data }) => {
    return (
        <Layout>
            <div className='flex items-center justify-center'>
                <h1 className='my-4 text-4xl font-bold text-center md:my-12 text-rickshaw-blue'>Can't find my way home!</h1>
            </div>
            <section className='flex flex-col items-center max-w-screen-lg mx-auto'>
                <p className='text-2xl font-medium text-gray-500 '>We can't find what you are looking for</p>
                <img src={data.NotFound.publicURL} alt='Not found' height='400' />
            </section>
        </Layout>
    );
};

export default NotFoundPage;
